import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/body/pages/about"
import NgAboutUs from "../components/body/pages/about/investors"

const AboutPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/about/"}
      title="About Kuda | The Money App for Africans"
      description="We came from frustration with excessive bank charges (for inconsistent services), now we’re here. We are the money app for Africans around the world."
    />
    <NgAboutUs/>
  </Layout>
)

export default AboutPage
