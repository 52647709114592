import React from "react"
import { CenteredHeroHeaderStyle } from "../en-ng/business/styles/InnerPagesStyle";
import EntryBadges from "./badges.js"
import QR from "./qr.js"
import EntryBadgesV2 from "./badgesV2.js"

const CenteredInnerPageHeader = (props) => {

    return (
        <CenteredHeroHeaderStyle>
            <div className="kuda-ignore--container career-noMarginTop">
                <div className="kuda-section--inner business-centered-header">
                    <div className="text-center justify-center flex flex-column">

                        <div className="business-header-container flex flex-column justify-center">

                            <h1 className="mx-auto business-header-title color-primary">
                                {props?.title}
                            </h1>

                            {props.isDownload && (
                                <p
                                    className={`mx-auto card-main--heading text-semi-bold color-black title-bottom--spacing mobile-only`}
                                >
                                    We’re here to help you get the best out of your money, no
                                    strings attached. Welcome to your freedom!
                                </p>
                            )}
                            <p className={`mx-auto business-header-subtext color-black  title-bottom--spacing
                                ${props.isDownload ? "desktop-only" : ""}`}>
                                {props?.subtitle}
                            </p>
                            <div className="flex justify-center mx-auto">

                            
                            {props.isDownload ? (
                                <QR />
                            ) : (
                                <EntryBadges
                                    className={props.ctaClass}
                                    isBusiness={props.isBusiness}
                                    isAmbassador={props.isAmbassador}
                                    ambassadorUrl={props.ambassadorUrl}
                                    hideDownload={props.hideDownload}
                                />
                            )}
                            </div>
                        </div>
                        {props.illustration && (
                            <div className="kuda-section--50">
                                <div className="kuda-illustration--center">
                                    {props.illustration}
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </CenteredHeroHeaderStyle>
    )
}

export default CenteredInnerPageHeader;
