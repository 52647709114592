import React from "react";
import { OurMilestoneStyled } from "./about-styles";

const Milestone = (props) => {


    return (
        <OurMilestoneStyled>
            <div className="kuda-section kuda-standard--section kuda-story--section">
                <div className="kuda-section--inner">
                    <div className="kuda-section--100">
                        <h2 className="kuda-section--heading text-center text-xlbold color-primary timeline-title-bottom--spacing">
                        Company Milestones
                        </h2>
                      <div className="flex justify-center mx-auto pb-5">
                      <div className="timeline">
                            {props?.milestones.map((yearData, index) => (
                                <div key={index} className="timeline-year" style={{ border: `3px solid ${yearData.color}`, color: `${yearData?.colorContrast}` }}>
                                    <h2 className="year-title text-xlbold text-center" style={{color: yearData.color}}>{yearData.year}</h2>
                                    <ul className="diclaimer-list color-black">
                                        {yearData.events.map((event, i) => (
                                            <li key={i}>                                                
                                                <span className="description">{event.description}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </OurMilestoneStyled>
    )
}

export default Milestone;
