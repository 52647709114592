import styled from "styled-components";

export const BoardOfDirectorsStyled = styled.div`
    .directors--wrap {
        max-width: 700px;
        column-gap: 85px;
    }
    .kuda-profile--wrap:not(:last-child) {
        margin-right: 0
    }
    @media only screen and (max-width: 767px) {
        .directors--wrap {
            column-gap: 15px;
        }    
        .kuda-profile--wrap {
            width: 210px
        }
    }
`
export const OurMilestoneStyled = styled.div`
    padding-bottom: 140px;
    .timeline {
        max-width: 860px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        column-gap: 85px;
        row-gap: 65px;
        justify-content: center;
    }
    .timeline-title-bottom--spacing {
        margin-bottom: 83px;
    }
    .timeline-year {
        width: 350px;
        padding: 20px;
        border-radius: 50px;
        margin: 10px;
        box-shadow:-34px 37px 0
    }

    @media only screen and (max-width: 425px) {
         .timeline-year {
            width: 100%;
            margin: 0 15px;
            max-width: 290px
        }
            
         .timeline {
            display: flex;
            column-gap: 15px;
            row-gap: 20px;
        }
    }
    @media only screen and (max-width: 767px) {
         .timeline {
            display: flex;
            column-gap: 15px;
            row-gap: 60px;
        }  
        .timeline-title-bottom--spacing {
            margin-bottom: 20px;
        }
        .timeline-year {
            box-shadow:-29px 33px 0
        }
    }

    .year-title {
        font-size: 4.25rem;
        margin-bottom: 15px;
    }

    .event-list {
        padding: 0;
        list-style-type: disc;
    }

    .event-list li {
        margin-bottom: 10px;
    }
`
export const RiskManagementStyled = styled.div`
 img {
      max-width: 120px
}
.desktop-only img {
  float: left;
}
    .p-text {
        line-height: 26px;
        font-size: 16px;
    }
        @media only screen and (max-width: 425px) {
        .p-text {
        line-height: 20px;
        font-size: 14px;
    }
        }
`
export const ImageSliderStyles = styled.div`
    .multi-slider-container {
    position: relative;
    overflow: hidden;
    max-height:420px
    }

    .slider-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
    }

    .slide {
    flex-shrink: 0;
    width: 410px; /* Adjust for number of slides per view */
    }

    .slide img {
        width: 100%;
        height: auto;
    }

    .prev-btn,
    .next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        padding: 15px 20px;
        border-radius: 50%;
        cursor: pointer;
        background: white;
        z-index: 10;

    }

    .prev-btn {
    left: 0;
    }
    .prev-btn svg {
    transform: rotate(180deg)
    }

    .next-btn {
    right: 0;
    }
  @media only screen and (max-width: 767px) {
    .slide {    
        width: 318px; /* Adjust for number of slides per view */
    }
    .multi-slider-container {
        max-height:215px
    }
  }
`