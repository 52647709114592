import React from "react";
import Babs from "../../../../../assets/img/babs_ceo.svg"
import Don from "../../../../../assets/img/don-okhuofu.svg"
import Musty from "../../../../../assets/img/musty_cto.svg";
import Ehia from "../../../../../assets/img/ehia-erhaboh.svg";
import Deborah from "../../../../../assets/img/deborah-nicol.svg";
import { Img } from "react-image";
import About2 from "../../../../../assets/img/blur/about2.inline.svg";
import About3 from "../../../../../assets/img/blur/about3.inline.svg";
import { BoardOfDirectorsStyled } from "./about-styles";

const BoardOfDirectors = () => {


    return (
        <BoardOfDirectorsStyled>
            <div className="kuda-section">
                <div className="kuda-section--inner">
                    <div className="kuda-section--100">
                        <h2 className="kuda-section--heading text-center text-xlbold color-primary title-bottom--spacing">
                            Our Board of Directors
                        </h2>

                    </div>
                    <div className="flex justify-center mx-auto kuda-section--100">
                        <div className="directors--wrap flex justify-center flex-wrap">
                            <div className="kuda-profile--wrap bottom--spacing">

                                <Img alt="kuda Microfinance Bank Chairman/ Non-Executive Director Don Okhoufu"
                                    src={Don}
                                    loader={<About2 className="blur kuda-team--member" />} className="kuda-team--member"
                                />
                                <div className="kuda-profile--item text-center flex flex-column bottom--spacing">
                                    <div className="color-primary text-xlbold mb-3">Don Okhoufu</div>
                                    <div className="color-black mb-3">Chairman/ Non-Executive Director</div>
                                    <div>
                                        <a href="https://www.linkedin.com/in/don-okhuofu-01266417/" target="_blank" rel="noopener noreferrer" className="cursor-pointer color-primary learn-more mt-0">LinkedIn</a>
                                    </div>
                                </div>
                            </div>
                            <div className="kuda-profile--wrap bottom--spacing">
                                <Img alt="kuda Microfinance Bank Managing Director Mutairu Mustapha"
                                    src={Musty}
                                    loader={<About3 className="blur kuda-team--member" />} className="kuda-team--member"
                                />
                                <div className="kuda-profile--item text-center flex flex-column bottom--spacing">
                                    <div className="color-primary text-xlbold mb-3">Mutairu Mustapha</div>
                                    <div className="color-black mb-3">Managing Director</div>
                                    <div>
                                        <a href="https://www.linkedin.com/in/mustaphamo/" target="_blank" rel="noopener noreferrer" className="cursor-pointer color-primary learn-more mt-0">LinkedIn</a>
                                    </div>
                                </div>
                            </div>
                            <div className="kuda-profile--wrap bottom--spacing">

                                <Img alt="kuda Microfinance Bank Non-Executive Director Babatunde Ogundeyi"
                                    src={Babs}
                                    loader={<About2 className="blur kuda-team--member" />} className="kuda-team--member"
                                />
                                <div className="kuda-profile--item text-center flex flex-column bottom--spacing">
                                    <div className="color-primary text-xlbold mb-3">Babatunde Ogundeyi</div>
                                    <div className="color-black mb-3">Non-Executive Director</div>
                                    <div>
                                        <a href="https://www.linkedin.com/in/babsogundeyi/" target="_blank" rel="noopener noreferrer" className="cursor-pointer color-primary learn-more mt-0">LinkedIn</a>
                                    </div>
                                </div>
                            </div>
                            <div className="kuda-profile--wrap bottom--spacing">
                                <Img alt="kuda Microfinance Bank Independent Non-Executive Director Ehia Erhaboh"
                                    src={Ehia}
                                    loader={<About3 className="blur kuda-team--member" />} className="kuda-team--member"
                                />
                                <div className="kuda-profile--item text-center flex flex-column bottom--spacing">
                                    <div className="color-primary text-xlbold mb-3">Ehia Erhaboh</div>
                                    <div className="color-black mb-3">Independent Non-Executive Director</div>
                                    <div>
                                        <a href="https://www.linkedin.com/in/ehia-erhaboh/" target="_blank" rel="noopener noreferrer" className="cursor-pointer color-primary learn-more mt-0">LinkedIn</a>
                                    </div>
                                </div>
                            </div>
                            <div className="kuda-profile--wrap bottom--spacing">
                                <Img alt="kuda Microfinance Bank Non-Executive Director  Deborah Nicol-Omeruah"
                                    src={Deborah}
                                    loader={<About3 className="blur kuda-team--member" />} className="kuda-team--member"
                                />
                                <div className="kuda-profile--item text-center flex flex-column bottom--spacing">
                                    <div className="color-primary text-xlbold mb-3">Deborah Nicol-Omeruah</div>
                                    <div className="color-black mb-3">Non-Executive Director </div>
                                    <div>
                                        <a href="https://www.linkedin.com/in/deborah-nicol-omeruah/" target="_blank" rel="noopener noreferrer" className="cursor-pointer color-primary learn-more mt-0">LinkedIn</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BoardOfDirectorsStyled>

    )
}

export default BoardOfDirectors;
